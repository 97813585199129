import React from "react"
import { graphql } from "gatsby"
import { NoteTemplateInterface } from "../@types/templates/note"
import Layout from "../components/layout"
import Note from "../components/notes/note"
import SEO from "../components/seo"
import tw from "twin.macro"
import styled from "@emotion/styled"

const NoteTemplate: React.FC<NoteTemplateInterface> = ({ data }) => {
  const Container = styled.div`
    ${tw`
      flex flex-col
      xl:w-8/12 xl:self-center
      `}
  `
  return (
    <Layout>
      <SEO
        title={data.notesJson.content.substr(0, 50) + "..."}
        type={"article"}
        publishedTime={data.notesJson.published}
        description={data.notesJson.content}
      />
      <Container>
        <Note
          avatar={data.site.siteMetadata.profileImage.childImageSharp.fixed}
          content={data.notesJson.content}
          permalink={data.notesJson.fields.permalink}
          slug={data.notesJson.fields.slug}
          published={data.notesJson.published}
        />
      </Container>
    </Layout>
  )
}

export default NoteTemplate

export const query = graphql`
  query ($slug: String) {
    notesJson(fields: { slug: { eq: $slug } }) {
      content
      published
      fields {
        slug
        permalink
      }
    }
    site {
      siteMetadata {
        profileImage {
          childImageSharp {
            fixed(width: 38) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
